<template>

	<div class="main-contents">
		
		<div class="tit mb-7-5">프로젝트 관리<img src="/images/admin/list_shortcut.png" alt="목록가기" @click="$router.go(-1)"/>
			<div class="show-date" @click="projectStart != '4' ? showChange() : ''">
				<div class="list">
					<div class="list-box show">승인여부 : </div> <SelectComp class="ap" tagName="div" type="text" list="Y:승인,N:미승인" v-model="apprYn"/>
					<span class="list-box"><nbsp n="2"/>/<nbsp n="2"/></span>
					<div class="list-box date">프로젝트 수주일 :</div> <div class="list-box cont">{{contractYmd}}</div>
				</div>				
			</div>
		</div>
		<div class="contents-body demand">

			<template>

				<div>
					<!-- 프로젝트 상태 -->
					<div class="show-box prj">
						<div v-if="projectInfo.projectStatus == '1'" class="state-mark pf">수 행 중</div>
						<div v-else-if="projectInfo.projectStatus == '2'" class="state-mark pf_ep">수 행 예 정</div>
						<div v-else-if="projectInfo.projectStatus == '9'" class="state-mark pf cp">수 행 완 료</div>	
						<div v-else-if="projectInfo.projectStatus == '4'" class="state-mark pf us">제 안 단 계</div>

						<!-- 미승인 표시 -->
						<nbsp/>
						<div v-if="(projectInfo.apprYn == 'N')" class="state-mark no">미 승 인</div>
					</div>

					<!-- 프로젝트명 -->
					<div class="prj_outline">
						<div class="name">
							<div class="label" style="vertical-align: top;">프로젝트명</div><div class="value name" >{{projectInfo.projectNm}}</div>
						</div>

						<div class="outline">
							<div class="label ivl03" style="margin-right: 22px;">개요</div><div class="value multiText" style="line-height: 18px;">{{projectInfo.projectDtlDesc}}</div>
						</div>

						<div class="outline">
							<div class="label ivl02">고 객 사</div><div class="value">{{projectInfo.clientNm}}</div>
						</div>

						<div class="outline">
							<div class="label ivl02" style="vertical-align: top;">산업분야</div><div class="value" style="width: 520px;"><SelectComp type="text" cdId="PRO105" :value="projectInfo.projectIndtypeClassCd.substr(0,1)"/> / <SelectComp type="text" cdId="PRO143" :value="projectInfo.projectIndtypeClassCd"/></div>
						</div>

						<div class="date">
							<div class="label ivl01">등 록 일 자</div><div class="value">{{projectInfo.regYyyymmdd}}</div>
						</div>

						<div class="btn-box">
							<div class="btn"><img src="/images/tec/prj/list_view.png" alt="목록보기" @click="$router.go(-1)"/></div>
							<template>
								<!-- <div v-if="type == '3'" class="btn" @click="goModifyProject()"><img src="/images/prj_change.png" alt="프로젝트 수정하기"/></div> -->
								<div class="btn" style="margin-left: 10px;" @click="goAdmModifyProject()"><img src="/images/prj_change.png" alt="프로젝트 수정하기"/></div>
							</template>
							<div class="btn">
								<div class="ci_box">
									<ImageComp type="image" :src="'/api/prj/sco/choice/projectCorpImg?parentProjectSeq='+projectInfo.parentProjectSeq" defaultSrc="COMPANY" alt="고객사 로고"/>
								</div>
							</div>
						</div>
					</div>

					<!-- 프로젝트 개요 -->      
					<div class="prj_outline">
						<div class="outline">
							<div class="label ivl02">수 행 사</div><div class="value">{{projectInfo.corpNm}}</div>
						</div>

						<div class="outline">
							<div class="label ivl02">담 당 자</div>
							<div v-if="projectInfo.corpMgrMberNm" class="value">
								{{projectInfo.corpMgrMberNm}}
							</div>
							<div v-else class="value">-</div>
							<div v-if="$store.state.userInfo.mberDivCd == '00'" class="change" @click="clickChangeCorpMgrMberNmAdm()">담당자 교체 &#9654;</div>
						</div>
						
						<div class="outline">
							<div class="label" style="margin-right: 47px;">프로젝트기간</div><div class="value">{{projectInfo.projectStartEndYyyymmdd}}</div>
						</div>
					</div>

					<!-- 지정 공급기업 -->
					<div class="prj_outline last">
						<div class="outline">
							<div class="label" style="margin-right:28px">지정된 공급기업</div>
							<div class="value">
								<div class="dutyList">
									<span class="dutyitem" v-for="(corpList, index) in projectChoiceCorpList" :key="index">
										<span>{{corpList.corpNm}}</span>
									</span>
								</div>
							</div>
						</div>
					</div>

				</div>
			</template>

		</div>

	</div>

</template>

<script>

export default {

	data() {
		return {
			projectSeq : 0,		
			apprYn : '',
			contractYyyymmdd : '',
			contractYmd : '',	
			projectStart : '',

			projectInfo : {},
            projectChoiceCorpList : [],
		};
	},
	beforeCreate() {
		// console.log('beforeCreate');
	},
	created() {
		// console.log('created');
	},
	beforeMount() {
		// console.log('beforeMount');
		if(this.$route.query.projectSeq != undefined && this.$route.query.projectSeq != '') {
			this.projectSeq = Number(this.$route.query.projectSeq);
		}		
		this.getProjectAppr();

		this.getProjectInfo(); 

	},
	mounted() {
		// console.log('mounted');
	},
	beforeUpdate() {
		// console.log('beforeUpdate');
	},
	updated() {
		// console.log('update');
	},
	beforeDestroy() {
		// console.log('beforeDestroy');
	},
	destroyed() {
		// console.log('destroyed');
	},

	methods: {
		getProjectAppr() {
			var param = {
				projectSeq : this.projectSeq,
				parentProjectYn : 'Y'
			}
			this.$.httpPost('/api/prj/adm/getProjectAppr', param)
				.then(res => {
					//console.log('getProjectAppr RESULT', res);
					this.apprYn = res.data.apprYn == 'N' ? 'N' : 'Y';
					this.contractYmd = res.data.contractYyyymmdd == undefined ? '-' : res.data.contractYyyymmdd.substr(0,4) + ". " + res.data.contractYyyymmdd.substr(4,2) + ". " + res.data.contractYyyymmdd.substr(6,2);
					this.contractYyyymmdd = res.data.contractYyyymmdd == undefined ? '-' : res.data.contractYyyymmdd;
					this.projectNm = res.data.projectNm;
					this.projectStart = res.data.projectStart;
					//this.projectDivCd = res.data.projectDivCd;
				}).catch(this.$.httpErrorCommon);
		},
		showChange() {
			//console.log("click");

			var params = {
				projectSeq : this.projectSeq,
				projectNm : this.projectNm,
				apprYn : this.apprYn,
				contractYyyymmdd : this.contractYyyymmdd == '-' ? null : this.contractYyyymmdd,
				parentProjectYn : "Y"
				//projectDivCd : this.projectDivCd,
			}
			// console.log(params);
			this.$.popup('/adm/prj/PRJ911P06', params)
                .then(res => {
                    if(res) {
                        this.$router.go(0);
                    }
                });
		},

		getProjectInfo() {

			var input = {
				parentProjectSeq : this.projectSeq,
				// tecMberSeq : this.tecMberSeq,
				// type : this.type
			}

			this.$.httpPost('/api/prj/sco/choice/getParentProjectInfo', input)
				.then(res => {
					// console.log(res.data);
					this.projectInfo 			= res.data.projectInfo;
			
					if(this.projectInfo.choiceCorpList != undefined) {
						this.getChoiceScoList(this.projectInfo.choiceCorpList);
					}
				})
				.catch(err => {
					// console.log('============================='); 
					// console.log(err);
					// console.log(err.response);
					// console.log('============================='); 

					alert(err.response.data.error_description);
				});
		},

		getChoiceScoList(choiceCorpList) {            

		// 지정공급기업목록 변환
		var param = {
			corpSeqList : choiceCorpList.split(',')
		}

		this.$.httpPost('/api/prj/getChoiceScoList', param)
			.then(res => {
				this.projectChoiceCorpList = res.data.choiceCorpList;
			}).catch(this.$.httpErrorCommon);

		},

		clickChangeCorpMgrMberNmAdm() {
            
			var params = {
				projectSeq : this.projectSeq,
                projectNm : this.projectInfo.projectNm,
				corpSeq : this.projectInfo.corpSeq,
                corpNm : this.projectInfo.corpNm,
                				 
                currCorpMgrMberSeq : this.projectInfo.corpMgrMberSeq,    // 현재 수요기업 프로젝트 담당자
                currCorpMgrMberNm : this.projectInfo.corpMgrMberNm,
                currCorpMgrLoginId : this.projectInfo.corpMgrLoginId,

				parentProjectYn : "Y",
                
				caller : {
					name: this.$route.name,
					params: this.projectSeq
				}
			}

			this.$router.push({name: 'PRJ920M04', params });

        },


		goAdmModifyProject() {
            // if(!this.editable) return;
            var params = {
				caller : {
					name: this.$route.name,
					params: this.input
				}
			}

			this.$router.push({name: 'PRJ920M03', query : {projectSeq : this.projectSeq, parentProjectYn : 'Y'}, params });
        },

	},
};
</script>
